import React, { useState, useEffect } from "react";
import MainVellowLayout from "../components/MainVellowLayout";
import { useAuth } from "../auth/authContext";
import {
  Button,
  Card,
  CardContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CheckHealth from "../api/queries/systemHealth";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export default function Home() {
  const { authData } = useAuth();
  const navigate = useNavigate();

  const [systemHealth, setSystemHealth] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSystemHealth = async () => {
      try {
        const response = await CheckHealth({
          Authorization: "Bearer " + authData.bearerToken,
        });
        setSystemHealth(response);
      } catch (error) {
        console.error("Failed to fetch system health:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSystemHealth();
  }, [authData.bearerToken]);

  const getStatusIcon = (status) => {
    return status === "Up" ? (
      <CheckIcon color="success" />
    ) : (
      <CloseIcon color="error" />
    );
  };

  return (
    <MainVellowLayout>
      <Typography variant="h5" marginBottom="20px">
        Welkom <b>{authData.email}</b>
      </Typography>
      <div style={{ display: "flex", marginBottom: "1em" }}>
        <Card variant="outlined" style={{ flex: "1" }}>
          <CardContent>
            <Typography variant="h5" component="h2" gutterBottom>
              Systeem informatie
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Huidige status van onze systemen
            </Typography>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                }}
              >
                <CircularProgress />
              </div>
            ) : systemHealth ? (
              <div>
                <Typography variant="body1">
                  <b>Endpoints:</b>
                </Typography>
                {Object.entries(systemHealth.data).map(([key, value]) => (
                  <li
                    key={key}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography variant="body2" style={{ marginRight: "5px" }}>
                      {key}:
                    </Typography>
                    {getStatusIcon(value)}
                  </li>
                ))}
              </div>
            ) : (
              <Typography variant="body1" color="error">
                No data available
              </Typography>
            )}
          </CardContent>
        </Card>
      </div>
      <div style={{ display: "flex", gap: "20px" }}>
        <Card variant="outlined" style={{ flex: "1" }}>
          <CardContent>
            <Typography variant="h5" component="h2" gutterBottom>
              Logs
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Controleer hier de logs van afas.
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate("/logs")}
            >
              Logs
            </Button>
          </CardContent>
        </Card>
        <Card variant="outlined" style={{ flex: "1" }}>
          <CardContent>
            <Typography variant="h5" component="h2" gutterBottom>
              Upload CSV
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Klik hier om door te gaan naar het uploaden van de CSV's.
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate("/csv")}
            >
              CSV Uploaden
            </Button>
          </CardContent>
        </Card>
      </div>
    </MainVellowLayout>
  );
}

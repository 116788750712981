import React, { useEffect, useState } from "react";
import {
  Chip,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import formatDate from "../functions/time";
import getSyncedData from "../../api/queries/syncedData";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/authContext";

export default function VellowTable() {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const { authData } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSyncedData({
          Authorization: "Bearer " + authData.bearerToken,
        });

        if (data) {
          const modifiedTableData = data.map((row) => {
            const syncedJsonData = row.syncedJsonData;
            const totalSyncedItems = JSON.parse(syncedJsonData).length;

            return {
              id: row.id,
              date: row.lastSyncDate,
              syncedJsonData: syncedJsonData,
              total:
                totalSyncedItems || totalSyncedItems === 0
                  ? totalSyncedItems
                  : 0,
            };
          });

          setTableData(modifiedTableData);
        } else {
          setTableData([]);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [authData.bearerToken]);

  const handleRowClick = (row) => {
    navigate(`/details/${row.id}`, {
      state: { rowData: row },
    });
  };

  return (
    <TableContainer component={Paper}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pl: 2, pt: 3, pb: 3 }}
      >
        <Typography variant="h5">AFAS</Typography>
      </Stack>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            {["ID", "Synced Datum", "Synced Items"].map((label, index) => (
              <TableCell key={index} sx={{ fontWeight: "bold" }}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={4}>
                {Array.from({ length: 5 }).map((_, key) => (
                  <Skeleton key={key} animation="wave" height={50} />
                ))}
              </TableCell>
            </TableRow>
          ) : tableData.length === 0 ? (
            <TableRow>
              <TableCell colSpan={3}>Geen data beschikbaar</TableCell>
            </TableRow>
          ) : (
            tableData.map((row, index) => (
              <TableRow
                hover
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => handleRowClick(row)}
              >
                {Object.entries(row)
                  .filter(([key]) => key !== "syncedJsonData")
                  .map(([key, value], index) => (
                    <TableCell key={index} sx={{ cursor: "pointer" }}>
                      {key === "date" ? (
                        formatDate(value)
                      ) : key === "total" ? (
                        <Chip label={value} />
                      ) : (
                        value
                      )}
                    </TableCell>
                  ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

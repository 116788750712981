export async function vellowApiServiceFile(
  endpoint: string,
  data: any,
  bearerToken: string
) {
  const url = process.env.REACT_APP_BASE_URL_API + endpoint;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${bearerToken}`,
      }),
      body: data,
    });

    if (response.ok) {
      const contentType = response.headers.get("Content-Type");
      if (contentType && contentType.includes("application/json")) {
        return await response.json();
      } else {
        return await response.text();
      }
    } else {
      return response;
    }
  } catch (error) {
    console.error("API request failed:", error);
  }
}

export async function vellowApiService(
  endpoint: string,
  method: string,
  data?: any,
  customHeaders?: Record<string, string>
) {
  const url = process.env.REACT_APP_BASE_URL_API + endpoint;

  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        ...customHeaders,
      },
      body: data ? JSON.stringify(data) : undefined,
    });

    if (response.ok) {
      return await response.json();
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

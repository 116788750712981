import { Alert, Snackbar } from "@mui/material";

interface SnackbarAlertProps {
  open: boolean;
  severity: string;
  message: string;
  handleClose: () => void;
  duration?: number;
}

export default function SnackbarAlert({
  open,
  severity,
  message,
  handleClose,
  duration = 5000,
}: SnackbarAlertProps) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        elevation={6}
        variant="filled"
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

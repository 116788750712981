import { vellowApiService } from "../vellowService";

export default async function CheckHealth(bearerToken) {
  return await vellowApiService(
    "AfasSync/CheckAllSystems",
    "GET",
    null,
    bearerToken,
  );
}

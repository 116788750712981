import React, { useState } from "react";
import {
  AppBar,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AccountCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/authContext";

export default function AppBarVellow({
  handleDrawerToggle,
}: {
  handleDrawerToggle: () => {},
}) {
  const { logout } = useAuth();

  const [accountOpen, setAccountOpen] = useState(false);

  const navigate = useNavigate();
  const handleAccountOpen = () => setAccountOpen(false);

  return (
    <AppBar position="fixed" color="inherit">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" sx={{ ml: 30 }}>
          Dashboard
        </Typography>
        <IconButton
          size="large"
          aria-haspopup="true"
          onMouseOver={() => setAccountOpen(true)}
          style={{ marginLeft: "auto" }}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={accountOpen}
          onClose={handleAccountOpen}
        >
          <MenuItem onClick={handleAccountOpen}>Mijn account</MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              logout();
              navigate("/");
            }}
          >
            Log uit
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

import { useLocation } from "react-router-dom";
import React, { useState } from "react";
import MainVellowLayout from "../components/MainVellowLayout";
import {
  FormControl,
  Icon,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import formatDate from "../components/functions/time";
import { SyncedData } from "../interfaces/vellow.interface";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

export default function Details() {
  const location = useLocation();
  const rowData = location.state?.rowData || null;

  const syncedJsonData: SyncedData[] = rowData?.syncedJsonData
    ? JSON.parse(rowData.syncedJsonData)
    : [];

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredSyncedJsonData = syncedJsonData.filter((data) =>
    Object.values(data).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <MainVellowLayout>
      {syncedJsonData.length > 0 ? (
        <TableContainer component={Paper}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            p={2}
          >
            <Typography variant="h6">
              ID: {rowData.id}, {formatDate(rowData.date)}
            </Typography>

            <FormControl variant="outlined">
              <InputLabel htmlFor="input-search">Zoeken</InputLabel>
              <OutlinedInput
                id="input-search"
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip title={searchQuery ? "Clear" : ""}>
                      <Icon edge="end" onClick={() => setSearchQuery("")}>
                        {searchQuery ? (
                          <ClearIcon sx={{ cursor: "pointer" }} />
                        ) : (
                          <SearchIcon />
                        )}
                      </Icon>
                    </Tooltip>
                  </InputAdornment>
                }
                label="Zoeken"
              />
            </FormControl>
          </Stack>
          <Table>
            <TableHead>
              <TableRow>
                {Object.keys(syncedJsonData[0]).map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSyncedJsonData.map((data: SyncedData, index) => (
                <TableRow key={index}>
                  {Object.values(data).map((value, colIndex) => (
                    <TableCell key={colIndex}>{value}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1">No synced items were found.</Typography>
      )}
    </MainVellowLayout>
  );
}

import { ReactNode } from "react";
import { Box } from "@mui/material";

export default function BackgroundImage({
  image,
  children,
}: {
  image: string,
  children: ReactNode,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        position: "relative",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8)), url(${image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundBlendMode: "multiply",
      }}
    >
      {children}
    </Box>
  );
}

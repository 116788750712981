import React, { useState } from "react";
import {
  Card,
  CardContent,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PasswordIcon from "@mui/icons-material/Password";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import lightLogoText from "../assets/img/logo-text-light.png";
import darkLogoText from "../assets/img/logo-text-dark.jpg";
import loginImage from "../assets/img/vellowBackground.jpg";
import Login from "../api/commands/login";
import SnackbarAlert from "../components/generic/snackbarAlert";
import { useAuth } from "../auth/authContext";
import BackgroundImage from "../components/generic/backgroundImage";

export default function LoginCard() {
  const navigate = useNavigate();
  const currentColorMode = useMediaQuery("(prefers-color-scheme: dark)");

  const { setAuth } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleSignIn = async () => {
    setLoading(true);
    try {
      const response = await Login({ username: email, Password: password });

      if (response && response.bearerToken) {
        setAuth({
          userId: response.userId,
          bearerToken: response.bearerToken,
          email: email,
        });
        navigate("/home");
      } else {
        setSnackbarState({
          open: true,
          message:
            "Verkeerde gebruikersnaam/wachtwoord. Controleer de gegevens.",
          severity: "error",
        });
      }
    } catch (error) {
      setSnackbarState({
        open: true,
        message: "Er is iets fout gegaan, probeer het later opnieuw.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    handleSignIn();
  };

  return (
    <BackgroundImage image={loginImage}>
      <Card sx={{ maxWidth: 500, p: 2, borderRadius: "10px" }}>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <img
              src={currentColorMode ? lightLogoText : darkLogoText}
              alt="logoText"
              width="100%"
            />
            <Typography variant="subtitle1" align="center">
              Biedt je de beste ervaring bij digitale transities
            </Typography>

            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              placeholder="Vul je e-mailadres"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Wachtwoord"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              type="password"
              placeholder="Vul je wachtwoord"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
              name="password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PasswordIcon />
                  </InputAdornment>
                ),
              }}
            />

            <LoadingButton
              sx={{ mt: 1 }}
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              disabled={email === "" || password === ""}
              loading={loading}
            >
              Log in
            </LoadingButton>
          </form>
        </CardContent>
      </Card>

      <SnackbarAlert
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        handleClose={handleSnackbarClose}
      />
    </BackgroundImage>
  );
}

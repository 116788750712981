import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React, { useState } from "react";
import { VisuallyHiddenInput } from "../mui/globalStyles";
import SnackbarAlert from "./snackbarAlert";

export default function UploadFileDropCard({
  title,
  subText,
  buttonText,
  acceptedExtensions,
  errorMessage,
  uploading,
  selectedFile,
  setSelectedFile,
  handleUpload,
}: {
  title: string,
  subText: string,
  buttonText: string,
  acceptedExtensions: string,
  errorMessage: string,
  uploading: any,
  selectedFile: any,
  setSelectedFile: any,
  handleUpload: any,
}) {
  const [showFileTypeWarning, setShowFileTypeWarning] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const [draggingText, setDraggingText] = useState(false);

  const handleFile = (file) => {
    if (file && file.name.endsWith(acceptedExtensions)) {
      setSelectedFile(file);
      setShowFileTypeWarning(false);
    } else {
      setSelectedFile(null);
      setShowFileTypeWarning(true);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleFile(file);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);
    setDraggingText(false);

    const file = event.dataTransfer.files[0];
    handleFile(file);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      onDragOver={(event) => {
        event.preventDefault();
        setDragOver(true);
        setDraggingText(true);
      }}
      onDragExit={(event) => {
        event.preventDefault();
        setDragOver(false);
        setDraggingText(false);
      }}
      onDrop={handleDrop}
    >
      <Card sx={{ width: 500, border: dragOver ? 0.5 : 0 }}>
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom>
            {title}
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />
          <Typography variant="subtitle1" gutterBottom>
            {draggingText
              ? "Sleep het bestand hier om het te uploaden"
              : subText}
          </Typography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <label htmlFor="file-upload">
              <Button
                component="span"
                variant="outlined"
                startIcon={
                  selectedFile ? <RestartAltIcon /> : <CloudUploadIcon />
                }
                disabled={uploading}
              >
                {selectedFile ? "Verander bestand" : buttonText}
              </Button>
            </label>
            <VisuallyHiddenInput
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              accept={acceptedExtensions}
              disabled={uploading}
            />
          </div>

          {selectedFile && (
            <Box sx={{ marginTop: 1, textAlign: "center" }}>
              <Typography variant="subtitle1">Geselecteerd Bestand:</Typography>
              <Typography variant="body1">{selectedFile.name}</Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpload}
                disabled={uploading}
                sx={{ marginTop: 1 }}
              >
                {uploading ? <CircularProgress size={24} /> : "Upload"}
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>
      <SnackbarAlert
        severity="error"
        open={showFileTypeWarning}
        handleClose={() => {
          setShowFileTypeWarning(false);
        }}
        message={errorMessage}
      />
    </Box>
  );
}

import AppBarVellow from "./AppBarVellow";
import DrawerVellow from "./DrawerVellow";
import { Box, TextField, Toolbar } from "@mui/material";
import { ReactNode, useState } from "react";
import VellowModal from "./generic/vellowModal";

export default function MainVellowLayout({
  children,
}: {
  children: ReactNode,
}) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBarVellow handleDrawerToggle={handleDrawerToggle} />
      <DrawerVellow
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        setModalOpen={setModalOpen}
      />
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - 240px)` },
        }}
      >
        <Toolbar />
        {children}
        <VellowModal
          title="Maak een support ticket aan"
          content={
            <>
              <TextField required label="Onderwerp" fullWidth />
              <TextField
                required
                label="Toelichting"
                multiline
                rows={4}
                fullWidth
              />
            </>
          }
          open={modalOpen}
          setOpen={setModalOpen}
        />
      </Box>
    </Box>
  );
}

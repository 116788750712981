import { vellowApiService } from "../vellowService";

export default async function getSyncedData(bearerToken) {
  return await vellowApiService(
    "AfasSync/GetSyncedData",
    "GET",
    null,
    bearerToken,
  );
}

import { createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import Haffer from "./assets/fonts/HafferSQ-Regular.woff2";
import Roslindale from "./assets/fonts/Roslindale-Narrow-Light.woff2";

const defaultTableStyles = {
  root: {
    borderRadius: "10px",
  },
};

const createCustomTheme = (mode) => ({
  typography: {
    fontFamily: "Haffer, Roslindale",
  },
  palette: {
    mode: mode,
    primary: {
      main: mode === "dark" ? grey[300] : grey[900],
    },
    secondary: {
      main: mode === "dark" ? grey[900] : grey[600],
    },
  },
  components: {
    MuiTableContainer: {
      styleOverrides: defaultTableStyles,
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Haffer';
          src: local('Haffer'), local('HafferSdQ-Regular'), url(${Haffer}) format('woff2');
        }
        @font-face {
          font-family: 'Roslindale';
          src: local('Roslindale'), local('Roslindale-Narrow-Light'), url(${Roslindale}) format('woff2');
        }
      `,
    },
  },
});

export const darkTheme = createTheme(createCustomTheme("dark"));
export const lightTheme = createTheme(createCustomTheme("light"));

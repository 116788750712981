import { Navigate } from "react-router-dom";
import { useAuth } from "../auth/authContext";

export default function PrivateRoute({ children, redirectPath = "/" }) {
  const { authData } = useAuth();

  if (!authData) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
}

import {
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import { modalStyle } from "../mui/globalStyles";

export default function VellowModal({
  title,
  content,
  open,
  setOpen,
}: {
  title: string,
  content: ReactNode,
  open: boolean,
  setOpen: () => {},
}) {
  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyle}>
          <Typography variant="h5" sx={{ mb: 3, fontFamily: "Roslindale" }}>
            {title}
          </Typography>
          <Stack spacing={3}>{content}</Stack>
          <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(false)}
            >
              Sluiten
            </Button>
            <Button variant="contained" color="primary">
              Verstuur
            </Button>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
}

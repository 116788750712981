import React, { useState } from "react";
import { Alert, Button, Collapse, IconButton, Typography } from "@mui/material";
import MainVellowLayout from "../components/MainVellowLayout";
import UploadCsv from "../api/commands/csv";
import UploadFileDropCard from "../components/generic/uploadFileDropCard";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../auth/authContext";
import formatDate from "../components/functions/time";

export default function Csv() {
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [csvUrl, setCsvUrl] = useState(null);

  const { authData } = useAuth();
  const downloadFilename =
    "uploaded_file_" + formatDate(new Date().toLocaleString()) + ".csv";

  const handleUpload = async () => {
    setUploading(true);

    const csvData = new FormData();
    csvData.append("file", selectedFile, selectedFile.name);

    try {
      const upload = await UploadCsv(csvData, authData.bearerToken);

      const blob = new Blob([upload], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      setCsvUrl(url);

      setAlertSeverity("error");
      setAlertMessage(
        "Het CSV-bestand is succesvol geüpload, maar bevat fouten. Controleer de fouten door het CSV-bestand te downloaden.",
      );
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(
        "Er is een fout opgetreden bij het uploaden van het CSV-bestand.",
      );
    } finally {
      setUploading(false);
      setSelectedFile(null);
      setAlertOpen(true);
    }
  };

  return (
    <MainVellowLayout>
      <Typography variant="h5" marginBottom={2}>
        CSV Upload
      </Typography>
      <UploadFileDropCard
        title="CSV Uploaden"
        subText="Selecteer een geldig CSV bestand om verder te kunnen gaan."
        buttonText="Upload Csv"
        acceptedExtensions=".csv"
        errorMessage="Upload een geldig .csv bestand"
        uploading={uploading}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        handleUpload={handleUpload}
      />
      <Collapse in={alertOpen} sx={{ mt: 1 }}>
        <Alert
          variant="outlined"
          action={
            <>
              {alertSeverity === "error" && csvUrl && (
                <Button
                  component="a"
                  href={csvUrl}
                  download={downloadFilename}
                  variant="outlined"
                  color="inherit"
                  size="small"
                  sx={{ mr: 1, textTransform: "capitalize" }}
                >
                  Download
                </Button>
              )}
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </>
          }
          sx={{ width: 500, m: "auto" }}
          severity={alertSeverity}
        >
          {alertMessage}
        </Alert>
      </Collapse>
    </MainVellowLayout>
  );
}

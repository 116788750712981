import React from "react";
import VellowTable from "../components/generic/vellowTable";
import MainVellowLayout from "../components/MainVellowLayout";
import { Typography } from "@mui/material";

export default function Logs() {
  return (
    <MainVellowLayout>
      <Typography variant="h5" marginBottom="20px">
        Logs
      </Typography>
      <VellowTable />
    </MainVellowLayout>
  );
}

import React from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import lightLogoText from "../assets/img/logo-text-light.png";
import darkLogoText from "../assets/img/logo-text-dark.jpg";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

export default function DrawerVellow({
  mobileOpen,
  handleDrawerToggle,
  setModalOpen,
}: {
  mobileOpen: () => {},
  handleDrawerToggle: () => {},
  setModalOpen: () => {},
}) {
  const currentColorMode = useMediaQuery("(prefers-color-scheme: dark)");
  const navigate = useNavigate();

  const drawer = (
    <div>
      <Toolbar sx={{ cursor: "pointer" }} onClick={() => navigate("/home")}>
        <img
          src={currentColorMode ? lightLogoText : darkLogoText}
          alt="logoText"
          width="90%"
        />
      </Toolbar>

      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" onClick={() => navigate("/home")} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/logs")}
            fullWidth
          >
            Logs
          </Button>
        </ListItem>
        <ListItem>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/csv")}
            fullWidth
          >
            CSV Uploaden
          </Button>
        </ListItem>
        <ListItem>
          <Button
            variant="contained"
            onClick={() => setModalOpen(true)}
            fullWidth
          >
            Support Ticket
          </Button>
        </ListItem>
        <ListItem>
          <Button
            variant="contained"
            color="secondary"
            component="a"
            href="https://www.vellow.nl/"
            target="_blank"
            fullWidth
          >
            Bezoek website
          </Button>
        </ListItem>
      </List>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: 240 }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 240,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 240,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

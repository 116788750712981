import AOS from "aos";
import "aos/dist/aos.css";
import "./App.css";
import { useEffect, useMemo } from "react";
import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import Login from "./screens/Login";
import Logs from "./screens/Logs";
import { darkTheme, lightTheme } from "./theme";
import { AuthProvider } from "./auth/authContext";
import PrivateRoute from "./components/PrivateRoute";
import Details from "./screens/Details";
import Home from "./screens/Home";
import Csv from "./screens/Csv";

export default function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  const currentColorMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = useMemo(
    () => (currentColorMode ? darkTheme : lightTheme),
    [currentColorMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/logs"
            element={
              <PrivateRoute>
                <Logs />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/csv"
            element={
              <PrivateRoute>
                <Csv />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/details/:id"
            element={
              <PrivateRoute>
                <Details />
              </PrivateRoute>
            }
          ></Route>
        </Routes>
      </AuthProvider>
    </ThemeProvider>
  );
}
